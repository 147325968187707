<template>
  <b-container class="py-5">
    <div v-if="!validDomain" class="row justify-content-md-center">
      <div class="col-8 alert alert-secondary" role="alert">
        The instructor(s) at this school has restricted the email address
        domain that can be used to become a SolidProfessor member.
        If you aren't sure what email address domain to use,
        please contact your instructor.
      </div>
    </div>
    <div class="row justify-content-center mb-4">
      <div class="col-12 text-center">
        <h3 v-if="user && user.organization">
          {{ user.organization.name }} Student
        </h3>
        <h3 v-if="selectedSchool && !(user && user.organization)">
          {{ selectedSchool.company }} Student
        </h3>
      </div>
    </div>
    <div v-if="user" class="row">
      <div class="col-12 col-sm-10 offset-sm-1 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
        <div v-if="newUser && !isLTIUser" class="alert alert-secondary text-center" role="alert">
          Fill out the form below to create a new account
        </div>
        <div v-else class="alert alert-secondary text-center" role="alert">
          Please enter your email address
        </div>
        <form>
          <fieldset>
            <!-- First/Last Name -->
            <div v-if="newUser" class="form-row">
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label for="first_name" :class="{ 'text-danger': errors.has('first_name') }">First Name</label>
                  <input
                    id="first_name"
                    key="first_name"
                    v-model="first_name"
                    v-validate="'required'"
                    class="form-control"
                    data-vv-validate-on="blur|change"
                    :class="{'is-invalid': errors.has('first_name') }"
                    type="text"
                    name="first_name">
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="form-group">
                  <label for="last_name" :class="{ 'text-danger': errors.has('last_name') }">Last Name</label>
                  <input
                    id="last_name"
                    key="last_name"
                    v-model="last_name"
                    v-validate="'required'"
                    class="form-control"
                    data-vv-validate-on="blur|change"
                    :class="{'is-invalid': errors.has('last_name') }"
                    type="text"
                    name="last_name">
                </div>
              </div>
            </div>
            <!-- Email -->
            <div class="form-row">
              <div class="col-12">
                <div class="form-group">
                  <label for="email" :class="{'text-danger': errors.has('email') }">Enter email address
                    <span v-show="errors.has('email')"><font-awesome-icon icon="exclamation-triangle" size="sm" fixed-width /></span>
                  </label>
                  <input
                    id="email"
                    key="email"
                    v-model="email"
                    v-validate="'required|email|isExistingEmail|isRestrictedEmail'"
                    class="form-control"
                    data-vv-validate-on="input|blur|change"
                    :class="{'is-invalid': errors.has('email') }"
                    type="text"
                    name="email"
                    :disabled="user.is_logged_in && userOrigin !== 'lti' && step < 3">
                  <div class="text-danger">
                    <small>{{ errors.first('email') }}</small>
                  </div>
                </div>
              </div>
            </div>
            <!-- Password -->
            <div v-if="newUser || existingUser" class="form-row">
              <div class="col-12">
                <div class="form-group">
                  <label for="password" :class="{'text-danger': errors.has('password') }">Password <span v-if="newUser">(at least 6 characters)</span> <span v-show="errors.has('password')"><font-awesome-icon icon="exclamation-triangle" size="sm" fixed-width /></span></label>
                  <input
                    id="password"
                    ref="password"
                    key="password"
                    v-model="password"
                    v-validate="'required|min:6'"
                    class="form-control"
                    :disabled="user.is_logged_in && userOrigin !== 'lti'"
                    data-vv-validate-on="blur|change"
                    :class="{'is-invalid': errors.has('password') }"
                    type="password"
                    name="password">
                </div>
              </div>
            </div>
            <div v-if="existingUser && loginError" class="row mb-2">
              <div class="col-12">
                <p class="text-danger">
                  <small>{{ loginError }}</small>
                </p>
              </div>
            </div>
            <div v-if="existingUser" class="row">
              <div class="col-6">
                <a :href="SPENV.APP_URL + '/iframes/reset-login.asp'"><small>Forgot your password?</small></a>
              </div>
              <div class="col-6 text-right">
                <b-link @click="toggleUserType">
                  <small class="font-weight-bold">Create Account</small>
                </b-link>
              </div>
            </div>
            <!-- Confirm password -->
            <div v-if="newUser" class="form-row">
              <div class="col-12">
                <div class="form-group">
                  <label for="confirm_password" :class="{'text-danger': errors.has('confirm_password') }">Confirm password <span v-show="errors.has('confirm_password')"><font-awesome-icon icon="exclamation-triangle" size="sm" fixed-width /></span></label>
                  <input
                    id="confirm_password"
                    key="confirm_password"
                    v-model="confirmPassword"
                    v-validate="'required|confirmed:password'"
                    data-vv-validate-on="blur|change"
                    class="form-control"
                    :class="{'is-invalid': errors.has('confirm_password') }"
                    type="password"
                    name="confirm_password">
                </div>
              </div>
              <div class="col-12 text-center">
                <b-link @click="toggleUserType">
                  <small class="font-weight-bold">Already have an Account? Log in</small>
                </b-link>
              </div>
            </div>
            <!-- Continue -->
            <div class="col-12 text-center mt-4">
              <b-button
                id="signup-submit"
                variant="primary"
                type="submit"
                name="signup"
                :disabled="!isFormValid"
                @click="validateForm">
                {{ existingUser ? 'Log In': 'Submit' }}
              </b-button>
            </div>
          </fieldset>
        </form>
        <div v-if="newUser" class="row">
          <div class="col-12 text-center mt-3">
            <small>By creating this membership, you’re agreeing to SolidProfessor's <a :href="SPENV.WWW_URL + '/terms-and-conditions/'" target="_blank" class="btn-link">Terms of Use</a> and <a :href="SPENV.WWW_URL + /privacy/" target="_blank" class="btn-link">Privacy Policy</a>.</small>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import analytics from '../../../../../shared/js/analytics.js';
import validationMixin from '@/utils/mixins/validation.js';

export default {
  name: 'StudentAccount',
  mixins: [validationMixin],
  data () {
    return {
      error_message: '',
      activeLoader: null,
      newUser: false,
      validDomain: true,
      existingUser: false,
      loginError: null,
    };
  },
  computed: {
    first_name: {
      get: function () {
        return this.$store.getters['studentStore/getName'];
      },
      set: function (firstName) {
        this.$store.commit('studentStore/setName', firstName);
      }
    },
    last_name: {
      get: function () {
        return this.$store.getters['studentStore/getLastName'];
      },
      set: function (lastName) {
        this.$store.commit('studentStore/setLastName', lastName);
      }
    },
    schools () {
      return this.$store.getters['studentStore/getSchools'];
    },
    step: {
      get: function () {
        return this.$store.getters['studentStore/getStep'];
      },
      set: function (step) {
        this.$store.commit('studentStore/setStep', step);
      }
    },
    userOrigin () {
      return this.$store.getters['studentStore/getUserOrigin'];
    },
  },
  created () {
    this.addCustomValidator();
  },
  mounted () {
    this.init();
  },
  methods: {
    init () {
      if (this.$route.query.returnURL) {
        this.$store.commit('params/setReturnURL', this.$route.query.returnURL);
      }
      this.$store.dispatch('studentStore/getOriginFromUser', this.user);
      this.existingUserAccount = this.user;
      this.incrementStep();
      // Used if coming from LTI
      if (this.userOrigin === 'lti') {
        this.isLTIUser = true;
        this.resetForm();
        this.errors.clear();
        this.getLtiSchool();
      } else {
        this.newUser = true;
      }
    },
    /**
     * Prevent the user from using the browser back button
     */
    noBackwardsNavigate () {
      if (this.step > 2) {
        this.nextNavigation();
      }
    },
    /**
     * Navigation can only flow forward
     * only increment the Store step if it is smaller than current
     */
    incrementStep () {
      if (this.step < 2) {
        this.step = 2;
      }
    },
    /**
     * Resets all form fields and clears errors
     * Because the fields are bound, each must be reset to clear the form
     * The validator must be reset as the final act to avoid race conditions
     *
     * @return does not return
     */
    resetForm () {
      this.email = null;
      this.password = null;
      this.confirmPassword = null;
      this.first_name = null;
      this.last_name = null;
      this.$nextTick(() => this.$validator.reset());
    },
    /**
     * Flips boolean on both user types if one of them is set
     *
     * @return does not return
     */
    toggleUserType () {
      if (this.newUser || this.existingUser) {
        this.newUser = !this.newUser;
        this.existingUser = !this.existingUser;
        this.$validator.validateAll();
      }
    },
    /**
     * Wrapper for submitted form validation.
     * `validateAll()` will return a boolean indicating success of validation
     *
     * IF VALID -- assign state email from form, proceed to next step in Login
     * ELSE -- does nothing
     *
     * @return does not return
     */
    validateForm (event) {
      event.preventDefault();
      this.validDomain = true;
      this.activeLoader = this.$loading.show();
      this.$validator.validateAll().then(
        response => {
          if (response) {
            this.email = this.$validator.fields.find({ name: 'email' }).value;
            this.setUserStatusOrLogin();
          }
        },
        error => {
          console.log(error);
        }
      );
    },
    /**
     * If newUser/Existing user is not set, calls function to set
     * Else will proceed to login
     *
     * @return does not return
     */
    setUserStatusOrLogin () {
      if (!this.newUser && !this.existingUser) {
        this.setUserStatus();
      } else {
        this.login();
      }
    },
    /**
     * Checks for existing email and then sets `existingUser`/`newUser` on results
     *
     * @return does not return
     */
    setUserStatus () {
      this.validateEmail()
        .then(response => {
          if (response) {
            this.existingUser = true;
            this.activeLoader.hide();
          } else {
            this.checkForValidDomain()
              .then(response => {
                if (response.data.validEmail) {
                  this.validDomain = true;
                  this.newUser = true;
                } else {
                  this.validDomain = false;
                }
                this.activeLoader.hide();
              });
          }
        });
    },
    /**
     * Determines login path from variables set on `this`, dismisses loader
     * NOTE: The order of the checking must not be altered
     *
     * @return does not return
     */
    login () {
      if (this.existingUser) {
        this.existingUserLogin();
      } else if (this.newUser && !this.isLTIUser) {
        this.createNewStudent();
      } else if (this.isLTIUser) {
        this.getLtiUserOnceUpdated();
      }
    },
    /**
     * A switch to find a variable property
     * One or the other should always exist
     *
     * @return string numerical id
     */
    getAdminID () {
      if (this.user && this.user.organization) {
        return this.user.organization.id;
      } else {
        return this.selectedSchool.rowid;
      }
    },
    /**
     * A switch to find a variable property
     * One or the other should always exist
     *
     * @return string numerical id
     */
    getCompany () {
      if (this.user && this.user.organization) {
        return this.user.organization.name;
      } else {
        return this.selectedSchool.company;
      }
    },
    /**
     * FOR LTI: this will find and set
     * the selectedSchool for LTI users,
     * since they are logged in we can use
     * the user.org.id
     */
    getLtiSchool () {
      let axiosConnectionData = {
        method: 'get',
        url: this.SPENV.APP_URL + '/api/v2/schools/' + this.user.organization.id,
        body: {}
      };
      this.$store.dispatch('studentStore/connectToAPI', axiosConnectionData)
        .then(
          response => {
            this.$store.commit('studentStore/setSelectedSchool', response.data[0]);
          },
          error => {
            console.log(error);
            this.activeLoader.hide();
          }
        );
    },
    /**
     * Collects data and submits to API to create new student.
     * On response calls next function
     */
    createNewStudent () {
      let axiosConnectionData = {
        method: 'post',
        url: this.SPENV.ACCOUNTS_API_URL + '/user',
        body: {
          name: this.first_name,
          lastName: this.last_name,
          email: this.email,
          password: this.password,
          confirm_password: this.confirmPassword,
          admin_id: this.getAdminID(),
          company: this.getCompany()
        }
      };
      this.$store.dispatch('studentStore/connectToAPI', axiosConnectionData)
        .then(
          () => {
            this.$store.dispatch('user/loadUserInfo').then(()=> {
              this.nextNavigation();
            });
          },
          error => {
            console.log(error);
            this.activeLoader.hide();
          }
        );
    },
    /**
     * Collects data and submits to API to log in existing user.
     *
     * On response calls next function
     */
    existingUserLogin () {
      let axiosConnectionData = {
        method: 'post',
        url: this.SPENV.ACCOUNTS_API_URL + '/login',
        body: {
          username: this.email,
          password: this.password
        }
      };
      this.$store.dispatch('studentStore/connectToAPI', axiosConnectionData)
        .then(
          response => {
            if (response.data.return_url) {
              this.$store.commit('studentStore/setExistingSchool', true);
              this.$store.dispatch('user/loadUserInfo').then(()=> {
                this.nextNavigation();
              });
            } else {
              this.loginError = 'Incorrect API response';
              this.activeLoader.hide();
            }
          },
          error => {
            console.log(error);
            this.loginError = 'Incorrect username or password';
            this.activeLoader.hide();
          }
        );
    },
    /**
     * Once user is establish in state, updates data and calls nextNavigations
     */
    getLtiUserOnceUpdated () {
      let axiosConnectionData = {
        method: 'get',
        url: this.SPENV.APP_URL + '/api/v2/user',
        body: {}
      };
      this.$store.dispatch('studentStore/connectToAPI', axiosConnectionData)
        .then(() => {
          this.nextNavigation();
        });
    },
    /**
     * Before navigating to the next page, ensure that the store is in sync what the values submitted on the form
     * @return {void}
     */
    syncStoreWithForm () {
      if (this.email && this.password) {
        this.$store.commit('user/setEmail', this.email);
        this.$store.commit('user/setPassword', this.password);
      }
    },
    /**
     * Wrapper for multiple things that have to happen before naving forward.
     * Most importantly, as LTI creates an initial account, make certain the DB is synced with the new form values
     *
     * @return {void} -- will result in pushing to the next step
     */
    nextNavigation () {
      analytics.setUser(this.user, this.SPENV.CHURNZERO_API_KEY);
      this.activeLoader.hide();
      this.step = 3;
      this.syncStoreWithForm();
      if (this.userOrigin  === 'lti' && this.user.email.indexOf('fax+') === 0) {
        this.$store.dispatch('user/updateUserInfo', { idcustomer: this.user.id, email: this.email, password: this.password, name: this.first_name, lastName: this.last_name})
          .then(() => {
            this.$router.push({name: 'LTI-StudentPayment'});
          });
      } else {
        this.$router.push({name: 'StudentPayment'});
      }
    }
  }
};
</script>
